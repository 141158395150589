import SEO from 'components/shared/Seo'
import { useStaticQuery, graphql } from 'gatsby'
import React from 'react'
import AboutWarriors from '../components/shared/AboutWarriors'
import Hero from '../components/shared/Hero'
import ImpactBlock from '../components/shared/ImpactBlock'

export default function NotFound() {

  const { sanityPageNotFound } = useStaticQuery(graphql`
  {
  sanityPageNotFound {
    pageNotFoundHero {
      heroText
      heroImage {
        asset {
          fluid {
            srcWebp
            src
            srcSet
            srcSetWebp
          }
          title
        }
      } 
    }
  }
}

  `)

  const { pageNotFoundHero } = sanityPageNotFound
  const { heroText, heroImage } = pageNotFoundHero
  return (
    <div>
      <Hero heroText={heroText} heroImage={heroImage} />
      <AboutWarriors />
      <ImpactBlock />
    </div>
  )
}
