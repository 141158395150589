import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

import { Container, H2, P } from '../../util/standard'
import { TABLET_BREAKPOINT, IMAC_BREAKPOINT, MOBILE_BREAKPOINT, TWENTY_INCH_SCREEN } from '../../util/constants'
import { Partial, Query, SanityImpactMetric } from '../../../graphql-types'
import BlockContent from '@sanity/block-content-to-react'
import { getFluidGatsbyImage } from 'gatsby-source-sanity'


interface Props {
  reversed?: boolean;
}

const ImpactWrapper = styled(Container)`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  margin:100px auto;

  @media only screen and (min-width: ${IMAC_BREAKPOINT}px) {
    width: 80%;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px){
    margin:50px auto;
  }
`
const ContentContainer = styled.div<{ reversedStyling?: boolean; }>`
width: 25%;
margin-left: 25%;
margin-right: 0px;
margin-bottom: 80px;
${({ reversedStyling }) => reversedStyling && `width: 28%; margin-left: 8%;`}

  @media only screen and (max-width: ${TWENTY_INCH_SCREEN}px) {
    margin-left: 20%;
    margin-right: 5%;
  ${({ reversedStyling }) => reversedStyling && `margin-left: 8%;`}
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 40px;
    ${({ reversedStyling }) => reversedStyling && `margin-left: 8%;`}

  }

  @media only screen and (min-width: ${IMAC_BREAKPOINT}px) {
    margin-left: 15%;
  ${({ reversedStyling }) => reversedStyling && `margin-left: 8%;`}
  }

  h2 {
    margin-top: 80px;
    color: black;
    font-family: 'bold';
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin-top: 0px;
  }
  }

`

const StyledH2 = styled(H2)`
  margin-top: 80px;
  color: black;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin-top: 0px;
  }
`


const ImageWrapper = styled.div<{ reversedStyling?: boolean; hideOnTablet?: boolean; hideOnDesktop: boolean; }>`
min-height: 460px;
width: 50%;
margin-left: 0px;
display: block;
${({ hideOnDesktop }) => hideOnDesktop && `display: none;`};
${({ hideOnTablet }) => hideOnTablet && `display: block;`};
${({ reversedStyling }) => reversedStyling && `transform: scaleX(-1);`};
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%; 
    height: 200px;
    margin: 0 auto;
  ${({ hideOnTablet }) => hideOnTablet && `display: none;`};
  ${({ hideOnDesktop }) => hideOnDesktop && `display: block;`};

  }

  @media only screen and (min-width: ${IMAC_BREAKPOINT}px) {
    width: 59%;
  }
`

const MetricNumber = styled(P)`
font-size: 20px;
margin: auto 10px;
`

const MetricName = styled(P)`
min-width: 150px;
`

const FeatureImg = styled(Img)`
height: 100%;
width: 100%;
`

const MetricContainer = styled(Container)`
margin-bottom: 10px;
`


function ImpactBlock(props: Props) {

  const { reversed } = props
  const { sanityImpactBlock, sanityHomeBlock }: Query = useStaticQuery(graphql`
  {
  sanityImpactBlock {
    impactMetrics {
      metricIcon {
        _key
        asset {
          fixed {
            srcWebp
            srcSetWebp
            srcSet
            src
          }
        }
      }
      metricNumber
      mainText
    }
    subText
    featureImage {
      asset {
        fluid {
          srcWebp
          srcSetWebp
          srcSet
          src
        }
        title
      }
    }
    mainText
  }
  sanityHomeBlock {
    impactBlockText {
      _rawContent
    }
    impactBlockImage {
      asset {
        fluid {
          srcWebp
          srcSetWebp
          srcSet
          src
        }
      }
    }
  }
}

  `)
  const { mainText, subText, impactMetrics, featureImage } = sanityImpactBlock
  const { impactBlockText, impactBlockImage } = sanityHomeBlock

  return (
    <ImpactWrapper>
      {!reversed &&
        <>
          <ContentContainer>
            <StyledH2 color="black" fontWeight="bold">{mainText}</StyledH2>
            <P>{subText}</P>
            {impactMetrics?.map((section: Partial<SanityImpactMetric>) => {
              const { metricIcon, metricNumber, mainText } = section
              return (
                <MetricContainer key={mainText}>
                  <img src={metricIcon?.asset?.fixed?.src} alt={metricIcon.asset.title ?? 'impact-metric-icon'} />
                  <MetricNumber fontWeight="bold">{metricNumber}</MetricNumber>
                  <MetricName>{mainText}</MetricName>
                </MetricContainer>
              )
            })}
          </ContentContainer>
          <ImageWrapper>
            <FeatureImg fluid={featureImage?.asset?.fluid} alt={featureImage?.asset?.title ?? 'impact-feature-image'} />
          </ImageWrapper>
        </>
      }
      {reversed &&
        <>
          <ImageWrapper hideOnTablet>
            <FeatureImg fluid={impactBlockImage?.asset?.fluid} alt={impactBlockImage?.asset?.title ?? 'impact-feature-image'} />
          </ImageWrapper>
          <ContentContainer reversedStyling={reversed}>
            <BlockContent blocks={impactBlockText._rawContent} />
          </ContentContainer>
          <ImageWrapper hideOnDesktop>
            <FeatureImg fluid={impactBlockImage?.asset?.fluid} alt={impactBlockImage?.asset?.title ?? 'impact-feature-image'} />
          </ImageWrapper>
        </>
      }
    </ImpactWrapper>
  )
}

export default ImpactBlock
