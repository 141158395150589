import React from "react"
import { useStaticQuery, graphql, navigate } from 'gatsby'
import styled from "styled-components"
import Img, { FluidObject } from "gatsby-image"

import ArrowLink from '../shared/sub/ArrowLink'
import { P, A, H2, Container } from "../../util/standard"
import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT, icons } from "../../util/constants"
import { Link } from "../../util/types"


const ContentBlockWrapper = styled(Container)`
  width: 80%;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
`

const ImageWrapper = styled(Container) <{ isMobile?: boolean }>`
  ${({ isMobile }) => isMobile && "display: none;"}
  width: 40%;
  min-width: 400px;
  margin: 150px auto 0px auto;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 50px 2% 20px 2%;
    width: 100%;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 0px 0px 50px 0px;
    min-width: 200px;
  }
`

const TextWrapper = styled(Container)`
  width: 40%;
  margin: 150px auto 0px auto;
  display: block;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    margin: 50px 0px 50px 0px;
  }
`

const ContentImg = styled(Img)`
  height: 100%;
  width: 100%;
`

const SubHeader = styled(P)``

const SubText = styled(P)``

const StyledH2 = styled(H2)`
color: black;
font-weight: 700;`

function aboutWarriors() {

  const { sanityAboutWarriorsCf } = useStaticQuery(graphql`
  {
  sanityAboutWarriorsCf {
    aboutWarriorsContent {
      contentSubtext
      contentSubHeader
      contentHeader
      contentLinks {
        _key
        url
        linkText
      }
      contentImage {
        asset {
          fluid {
            srcWebp
            srcSetWebp
            srcSet
            src
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
}

  `)

  const { aboutWarriorsContent } = sanityAboutWarriorsCf
  const { contentSubtext, contentHeader, contentSubHeader, contentImage, contentLinks } = aboutWarriorsContent
  return (
    <ContentBlockWrapper>
      <ImageWrapper>
        <ContentImg fluid={contentImage.asset.fluid} alt={contentImage?.asset?.title ?? 'about-content-image'} />
      </ImageWrapper>
      <TextWrapper>
        <SubHeader>{contentSubHeader}</SubHeader>
        <StyledH2 color="black">{contentHeader ?? "Warriors Community Foundation"}</StyledH2>

        <SubText>{contentSubtext}</SubText>
        {contentLinks.map((link: Link) => {
          return (
            <ArrowLink link={link} />
          )
        })}
      </TextWrapper>
      <ImageWrapper >
        <ContentImg fluid={contentImage as FluidObject} alt={contentImage?.asset?.title ?? 'about-content-image'} />
      </ImageWrapper>
    </ContentBlockWrapper >
  )
}

export default aboutWarriors
